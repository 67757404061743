export const formatAuthorNames = (name) => {
    
    if (name === undefined) {
        return '';
        }

    let names = name.split(' ');
        
    let capitalizeNames = names.map(name => {
        if (name.length <= 2) {
          return name;
        }

        let lowerCaseName = name.toLowerCase(); 
        return lowerCaseName.charAt(0).toUpperCase() + lowerCaseName.slice(1)  
    });

    let formattedName = capitalizeNames.join(' ');

    return formattedName;
    
}