import { PagegrowlerFunctions } from "@pagegrowler_nextjs";

const DEFAULT_IMAGE_URL = "/img/default.png";
const DEVICES_SCREEN_SIZES = process.env.deviceSizes;
const getCloudflareURL = (src) => {
  let finalSrc = src;
  if (!src.startsWith("/")) {
      if (process.env.NEXT_PUBLIC_CLOUDFLARE_IMAGE_DOMAIN) {
        finalSrc = `${process.env.NEXT_PUBLIC_CLOUDFLARE_IMAGE_DOMAIN}/format=auto/${src}`;
      }
  }
  return finalSrc;
}

const getFullImageObject = (element, clipping) => {
  return PagegrowlerFunctions()
    .getFullImageObject(
      element,
      clipping,
      process.env.NEXT_PUBLIC_IMAGE_DOMAIN,
      { url: DEFAULT_IMAGE_URL }
    )
}

const getImage = (element, clipping) => {
  if (typeof clipping === "string") {
    return getFullImageObject(element, clipping);
  }

  const img = getFullImageObject(element, clipping.default);
  return img.url.includes(DEFAULT_IMAGE_URL)
    ? getFullImageObject(element, clipping.fallback)
    : img;
}


const getImageMobile = (url, clippingDevice) => {
  const regex = /_([^_]+)__(\d)/;
  const match = regex.exec(url);
  if (!match) {
    return url;
  }
  const nuevaUrl = url.replace(regex, `_${clippingDevice}__1`);
  return nuevaUrl;
}

const getExtractSrcSet = (srcSet) => {
  const regex = /([0-9]+)x([0-9]+)/;
  const match = regex.exec(srcSet);

  if (!match) {
    return "";
  }
  return `${match[1]}x${match[2]}`;
}


const createImageObject = (props) => {
  const { element, clipping, src, alt, width, height, className, srcSet, ...rest } = props;
  
  if ((!props.element || !props.clipping) && !props.src) {
    return {};
  }
  
  if (props.element) {
    if (props.clipping) {
      if (props.srcSet) {
        let sources = [];
        const { element, clipping, srcSet, ...rest } = props;
        const deviceSizes = Object.entries(DEVICES_SCREEN_SIZES);

        let img;
        deviceSizes.forEach(device => {
          if (device[0] in srcSet) {
            img = getImage(element, srcSet[device[0]]);
            sources.push({
              media: device[1],
              src: getCloudflareURL(img.url)
            })
          } else {
            img = getImage(element, clipping);
            sources.push({
              media: device[1],
              src: getCloudflareURL(img.url)
            })
          }
        });

        img = getImage(element, clipping);
        return {
          src: getCloudflareURL(img.url),
          alt: img.title,
          sources,
          ...rest
        }
      }

      const { element, clipping, ...rest } = props;
      const img = getImage(element, clipping);

      return {
        src: getCloudflareURL(img.url),
        alt: img.title,
        ...rest
      }
    }
  }

  if (srcSet && src) {
    let sources = [];
    const deviceSizes = Object.entries(DEVICES_SCREEN_SIZES);
    let img;
    let mediaDevice
    deviceSizes.forEach(device => {
      if (device[0] in srcSet) {
        mediaDevice = getExtractSrcSet(srcSet.mobile)        
        img = (srcSet.mobile && srcSet.mobile.toLowerCase().indexOf('.jpg') >= 0) ? srcSet.mobile : getImageMobile(src, mediaDevice)
        sources.push({
          media: device[1],
          src: getCloudflareURL(img),
        })
      }
    });

    return {
      src: props.src,
      alt: alt,
      width,
      height,
      className,
      sources
    }
  }

  if (props.src) {
    return {
      ...props
    }
  }

  return { };
}

export default function Image(props) {
  const {
    src,
    alt,
    width,
    height,
    sources,
    className
  } = createImageObject(props);

  return (
    <picture style={{ width: "100%" }}>
      { sources && sources.map((source) => (
        <source key={source.media} media={`(max-width: ${source.media}px)`} srcSet={source.src} />
      ))}
      <img
        src={src}
        alt={alt}
        width={width}
        height={height}
        className={className}
        loading={"lazy"}
      />
    </picture>
  )
}